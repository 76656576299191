import React, { FC } from 'react';
import Accordion from 'react-tiny-accordion';
import classNames from 'classnames';
import Button from 'gatsby-theme-husky/src/common/Button';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import IconCustom from 'gatsby-theme-husky/src/common/IconCustom';
import { ControledAccordionProps } from 'gatsby-theme-husky/src/components/ControledAccordion/model';
import 'gatsby-theme-husky/src/components/ControledAccordion/ControledAccordion.scss';
import 'gatsby-theme-husky/src/components/ControledAccordion/ControledAccordionRTL.scss';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';

const ControledAccordion: FC<ControledAccordionProps> = ({ className, items, selectedIndex }) => {
  const { isMobile, isTablet } = useScreenRecognition();

  const index = selectedIndex
    ? selectedIndex >= items.length
      ? items.length - 1
      : selectedIndex - 1
    : null;

  const handleItems = () => {
    setTimeout(() => {
      const accordionItems = document.querySelectorAll('.accordion.faq-page__accordion > div');
      accordionItems.forEach((item: any) => {
        if (item.classList.contains('open')) {
          window.scrollTo({
            left: 0,
            top: item.offsetTop,
          });
        }
      });
    }, 50);
  };

  return (
    <Accordion
      className={classNames(`accordion`, className)}
      transitionDuration="300"
      {...(index !== null && { selectedIndex: index })}
      onChange={isMobile || isTablet ? handleItems : null}
    >
      {items?.length
        ? items.map(({ properties: { answer, question } }) => (
            <div
              className="accordion__answer"
              key={question}
              data-header={
                <Button classes="accordion__question">
                  <IconCustom className="accordion__chevron-icon" icon="arrow-down" />
                  <span>{question}</span>
                </Button>
              }
            >
              <DangerouslySetInnerHtml html={answer} />
            </div>
          ))
        : null}
    </Accordion>
  );
};

export default ControledAccordion;
